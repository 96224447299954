import React from 'react';
import Button from '@material-ui/core/Button';
import ColumnsIcon from '@material-ui/icons/ViewWeekOutlined';
import FilterIcon from '@material-ui/icons/FilterList';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import { useTranslation } from 'react-i18next';

import { NS } from '@tafs/i18n/i18nextConfig';

import styles from './index.module.css';

const CustomInstrumentsToolPanel = ({ stateKey, api, filterStateKey }) => {
  const { t } = useTranslation(NS.AG_GRID);

  const handleResetColumnState = () => {
    api.columnController.resetColumnState();
  };

  const handleResetFilterState = () => {
    api.setFilterModel([]);
    api.setSortModel({});
  };

  const handleSaveFilters = () => {
    localStorage.setItem(filterStateKey, JSON.stringify(api.getFilterModel()));
  };

  const handleApplyFilters = () => {
    const filterState = localStorage.getItem(filterStateKey);
    if (filterState) api.setFilterModel(JSON.parse(filterState));
  };

  return (
    <>
      {!!stateKey && (
        <>
          <Button
            variant="contained"
            onClick={handleResetColumnState}
            fullWidth
            className={styles.button}
          >
            <ColumnsIcon />
            <span className={styles.label}>{t('Reset column state')}</span>
          </Button>
          <Button
            variant="contained"
            onClick={handleResetFilterState}
            fullWidth
            className={styles.button}
          >
            <FilterIcon />
            <span className={styles.label}>{t('Reset filters')}</span>
          </Button>
          {filterStateKey && (
            <>
              <Button
                variant="contained"
                onClick={handleSaveFilters}
                fullWidth
                className={styles.button}
              >
                <SaveOutlinedIcon />
                <span className={styles.label}>{t('Save filters')}</span>
              </Button>
              <Button
                variant="contained"
                onClick={handleApplyFilters}
                fullWidth
                className={styles.button}
              >
                <OpenInBrowserIcon />
                <span className={styles.label}>{t('Apply saved filters')}</span>
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CustomInstrumentsToolPanel;
