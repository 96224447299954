import gql from 'graphql-tag';
import { getTime } from 'date-fns';

import { addPatternEscapeChars } from '@tafs/utils';

const { GPB_MODE } = process.env;

export const getAppPermissionsQuery = () => ({
  query: gql`
    query {
      AppPermissionEntityList {
        content {
          id
          value
        }
      }
    }
  `,
  fetchPolicy: 'no-cache',
});

export const getRuleFineTuningAnalyticQuery = ({ ruleCode, user }) => ({
  query: gql`
    query ($ruleCode: String!, $user: String!) {
      RefRulesMonitorEntityList(
        qfilter: {
          key: "ruleCode"
          operator: equals
          value: $ruleCode
          combinator: AND
          next: { key: "tuningsAnalytic.user", operator: equals, value: $user }
        }
      ) {
        content {
          id
          ruleCode
          ruleName
          tuningsAnalytic {
            id
            user
            priority
            params
          }
          ruleGroup
        }
      }
    }
  `,
  variables: {
    ruleCode,
    user,
  },
  fetchPolicy: 'no-cache',
});

export const getRuleFineTuningScheduleQuery = ({ ruleCode, workspace }) => ({
  query: gql`
    query ($ruleCode: String!, $workspace: String!) {
      RefRulesMonitorEntityList(
        qfilter: {
          key: "ruleCode"
          operator: equals
          value: $ruleCode
          combinator: AND
          next: {
            key: "tuningsSchedule.workSpace"
            operator: equals
            value: $workspace
          }
        }
      ) {
        content {
          id
          ruleCode
          ruleName
          tuningsSchedule {
            id
            priority
            params
          }
          ruleGroup
        }
      }
    }
  `,
  variables: {
    ruleCode,
    workspace,
  },
  fetchPolicy: 'no-cache',
});

export const getRuleParamsValidationQuery = (ruleCode) => ({
  query: gql`
    query ($ruleCode: String!) {
      RefRulesEntityList(
        qfilter: { key: "ruleCode", operator: equals, value: $ruleCode }
      ) {
        content {
          jsonParamsValidation
        }
      }
    }
  `,
  variables: {
    ruleCode,
  },
  fetchPolicy: 'no-cache',
});

export const areDealsInIncidentQuery = (id) => ({
  query: gql`
    query ($id: String) {
      DealsImportMonitorEntityList(
        paginator: { page: 1, size: 1 }
        qfilter: { key: "incidents.id", value: $id, operator: IN }
      ) {
        content {
          id
        }
      }
    }
  `,
  variables: {
    id,
  },
  fetchPolicy: 'no-cache',
});

export const areOrdersInIncidentQuery = (id) => ({
  query: gql`
    query ($id: String) {
      OrdersImportMonitorEntityList(
        paginator: { page: 1, size: 1 }
        qfilter: { key: "incidents.id", value: $id, operator: IN }
      ) {
        content {
          id
        }
      }
    }
  `,
  variables: {
    id,
  },
  fetchPolicy: 'no-cache',
});

export const getChartMarketDataQuery = ({
  dateFrom,
  dateTo,
  ticker,
  clients,
}) => ({
  query: gql`
    query (
      $ticker: String!
      $dateFrom: String!
      $dateTo: String!
      ${clients ? '$clients: String!' : ''}
    ) {
      MktEntityList(
        qfilter: {
          key: "mktDTime"
          value: $dateFrom
          operator: greaterThan
          combinator: AND
          next: {
            key: "mktDTime"
            value: $dateTo
            operator: lessThan
            combinator: AND
            next: { key: "ticker", value: $ticker, operator: equals }
          }
        }
      ) {
        content {
          mktDTime
          mktOpen
          mktHigh
          mktLow
          mktClose
          mktVolA
        }
      }
      BaseEntityList(
        qfilter: {
          key: "dealDtime"
          value: $dateFrom
          operator: greaterThan
          combinator: AND
          next: {
            key: "dealDtime"
            value: $dateTo
            operator: lessThan
            combinator: AND
            next: {
              key: "ticker"
              value: $ticker
              operator: equals
              ${
                clients
                  ? 'combinator: AND next: { key: "client", value: $clients, operator: IN }'
                  : ''
              }
            }
          }
        }
      ) {
        content {
          id
          dealDtime
          client
          baseDealId {
            dealId
            orderIdVenue
            monitorId
          }
          baseSpotForward {
            contractDirection
            volumeAsset
            price
          }
          incidents {
            id
            ruleId
            refRule {
              __typename
            }
          }
        }
      }
      BaseOrderSpotEntityList(
        qfilter: {
          key: "orderDtime"
          value: $dateFrom
          operator: greaterThan
          combinator: AND
          next: {
            key: "orderDtime"
            value: $dateTo
            operator: lessThan
            combinator: AND
            next: {
              key: "orderTicker"
              value: $ticker
              operator: equals
              combinator: AND
              next: {
                key: "orderDeals.active"
                value: "true"
                operator: equals
                ${
                  clients
                    ? 'combinator: AND next: { key: "client", value: $clients, operator: IN }'
                    : ''
                }
              }
            }
          }
        }
      ) {
        content {
          id
          orderDtime
          orderIdVenue
          orderPrice
          orderDirection
          client
          orderDeals {
            monitorId
            incidents {
              id
              ruleId
              refRule {
                __typename
              }
            }
          }
        }
      }
    }
  `,
  variables: {
    ticker,
    dateFrom: getTime(new Date(dateFrom)),
    dateTo: getTime(new Date(dateTo)),
    clients,
  },
});

export const getDealQuery = (dealId) => ({
  query: gql`
    query ($id: UUID!) {
      DealsImportMonitorEntity(id: $id) {
        boardId
        venue
        desk
        trader
        salesTeam
        dealDtime
        dealtype
        ticker
        settlementCurrency
        tradeCurrency
        backOffice
        middleOffice
        userId
        statusDeal
        location
        client
        counterparty
        dealmode
        portfolio
        contractdirection
        vdateAsset
        vdateBase
        price
        volumeasset
        volumeBase
        dealIdVenue
        orderIdVenue
        tradeCurrency
        refIssues {
          asset
          assetClass
          currencyContract
          secFullName
          secShortName
          businessLine
          #TODO: uncomment after TAFS-newAlgo2209 is merged on backend
          #tenor
          #tenorType
        }
        dealsUniqueHash
        isActualMm
        #price2
        #dealidvenuelinked
        #volumeassetUnits
      }
    }
  `,
  variables: {
    id: dealId,
  },
  fetchPolicy: 'no-cache',
});

export const getOrderQuery = (orderId) => ({
  query: gql`
    query ($id: UUID!) {
      OrdersImportMonitorEntity(id: $id) {
        boardId
        venue
        trader
        dTime
        dealtype
        direction
        ticker
        price
        location
        placementLocation
        client
        counterparty
        volumeasset
        volumebase
        accrued
        dealmode
        currency
        tradeCurrency
        dTimeAmended
        dTimeActivation
        idVenue
        status
        sourceId
        dTimeWithdrawn
        dTimeCancelled
        ordersUniqueHash
        isMarketMaker
        isActualMm
      }
    }
  `,
  variables: {
    id: orderId,
  },
  fetchPolicy: 'no-cache',
});

export const getOrderCalculatedQuery = (monitorId) => ({
  query: gql`
    query ($id: String!) {
      OrderDealSecondaryEntityList(
        qfilter: {
          key: "monitorId"
          value: $id
          operator: equals
          combinator: AND
          next: { key: "active", value: "true", operator: equals }
        }
      ) {
        content {
          orderVolumeassetMatched
          orderVolumebaseMatched
          orderDtimeFirst
          orderDtimeLast
          orderNumberDeals
        }
      }
    }
  `,
  variables: {
    id: monitorId,
  },
});

export const getIssueByTickerVenueQuery = (ticker, venue) => ({
  query: gql`
    query ($ticker: String!, $venue: String!) {
      RefIssuesMonitorEntityList(
        qfilter: {
          key: "ticker"
          value: $ticker
          operator: equals
          combinator: AND
          next: { key: "venue", value: $venue, operator: equals }
        }
      ) {
        content {
          asset
          assetClass
          businessLine
          currencyContract
        }
      }
    }
  `,
  variables: {
    ticker,
    venue,
  },
  fetchPolicy: 'no-cache',
});

export const getIssueIdByTickerVenueQuery = (ticker, venue) => ({
  query: gql`
    query ($ticker: String!, $venue: String!) {
      RefIssuesMonitorEntityList(
        paginator: { size: 1, page: 1 }
        qfilter: {
          key: "ticker"
          value: $ticker
          operator: equals
          combinator: AND
          next: { key: "venue", value: $venue, operator: equals }
        }
      ) {
        content {
          id
        }
      }
    }
  `,
  variables: {
    ticker,
    venue,
  },
  fetchPolicy: 'no-cache',
});

export const getIncidentReportRowQuery = (id) => ({
  query: gql`
    query ($id: UUID!) {
      IncidentReportRowMonitorEntity(id: $id) {
        salesTeam
        priority
        desk
        counterparty
        businessLine
        entity
        business
        isBlacklisted
        portfolio
        assetClass
        venue
        ticker
        user
        incidentStartDtime
        dealtype
        ruleId
        location
        trader
        ruleGroup
        client
        createdAt
        incidentEndDtime
        id
        serialId
        dealmode
        boardId
        workSpace
        probabilityOfTruth
        issuer
        liquidity
        isInsider
        isActualMm
        management {
          status
          assignBy
        }
        nestedIncidents {
          id
        }
      }
    }
  `,
  variables: {
    id,
  },
  fetchPolicy: 'no-cache',
});

export const getIncidentQuery = (id) => ({
  query: gql`
    query ($id: UUID) {
      IncidentMonitorEntity(id: $id) {
        refRule {
          ruleGroup
          ruleCode
          parentRefRule
          ruleName
        }
        id
        isBlacklisted
        priority
        user
        ruleId
        createdAt
        ruleGroup
        statistics {
          tuning
          metrics
          triggers
          genericMetrics
        }
        relatedIncidentsByMetrics {
          counterIncidentIds
          counterSignalsMarketMakerReportIds
          counterSignalsIds
          counterRequestSignals
        }
      }
    }
  `,
  variables: {
    id,
  },
  fetchPolicy: 'no-cache',
});

export const getAlgorithmsActivatedQuery = (workspace, user) => ({
  query: gql`
    query ($workspace: String, $user: String) {
      UsersRefRulesSettingEntityList(
        qfilter: {
          key: "user"
          value: $user
          operator: equals
          combinator: AND
          next: {
            key: "refRule.workSpaces.workSpaceName"
            value: $workspace
            operator: equals
          }
        }
      ) {
        content {
          isActivated
          ruleId
          refRule {
            ruleGroup
            parentRefRule
            ruleName
            archived
          }
        }
      }
    }
  `,
  variables: {
    workspace,
    user,
  },
  fetchPolicy: 'no-cache',
});

export const getScheduleAlgorithmsActivatedQuery = (workspace) => ({
  query: gql`
    query ($workspace: String) {
      FineTuningScheduleEntityList(
        qfilter: {
          key: "priority"
          value: "0"
          operator: equals
          operator: equals
          combinator: AND
          next: { key: "workSpace", value: $workspace, operator: equals }
        }
      ) {
        content {
          isActive
          rule {
            ruleCode
            ruleGroup
            parentRefRule
            ruleName
            archived
          }
        }
      }
    }
  `,
  variables: {
    workspace,
  },
  fetchPolicy: 'no-cache',
});

export const getUserDisplaySettingsQuery = (user, mode, workspace) => ({
  query: gql`
    query ($user: String, $mode: String, $workspace: String) {
      UsersDashboardSettingsEntityList(
        qfilter: {
          key: "uuser"
          value: $user
          operator: equals
          combinator: AND
          next: {
            key: "modeIsDashboard"
            value: $mode
            operator: equals
            combinator: AND
            next: {
              key: "modeIsWorkspace"
              value: $workspace
              operator: equals
            }
          }
        }
      ) {
        content {
          isGraphRule
          isKeyIndicatorRule
          filters
        }
      }
      RefRulesMonitorEntityList {
        content {
          ruleCode
          ruleGroup
          ruleName
          archived
        }
      }
    }
  `,
  variables: { user, mode, workspace },
  fetchPolicy: 'no-cache',
});

export const getUserFiltersQuery = (user) => ({
  query: gql`
    query ($user: String) {
      UsersDashboardSettingsEntityList(
        qfilter: { key: "uuser", value: $user, operator: equals }
      ) {
        content {
          modeIsWorkspace
          modeIsDashboard
          filters
        }
      }
    }
  `,
  variables: { user },
  fetchPolicy: 'no-cache',
});

export const getCaseQuery = (id) => ({
  query: gql`
    query ($id: UUID) {
      CaseEntity(id: $id) {
        description
        comment
        assigned
        created
        id
        caseType
        creator
        caseStatus
        subject
        workSpace
        incidentMonitorEntities {
          id
          mode
          ruleGroup
          ruleId
        }
      }
    }
  `,
  variables: {
    id: id,
  },
  fetchPolicy: 'no-cache',
});

export const getCaseActivitiesQuery = (id) => ({
  query: gql`
    query ($id: String) {
      CaseActivityEntityList(
        qfilter: { key: "caseId", value: $id, operator: equals }
      ) {
        content {
          id
          creator
          created
          plannedEnd
          started
          status
          type
          description
          files {
            id
            size
            contentType
            uploadAt
            name
          }
        }
      }
    }
  `,
  variables: {
    id: id,
  },
  fetchPolicy: 'no-cache',
});

export const getNewsQuery = (id) => ({
  query: gql`
    query ($id: UUID) {
      NewsEntity(id: $id) {
        date
        description
        issuerName
        tags
        issuerTaxId
        isSignificant
        link
        title
        id
        text
      }
    }
  `,
  variables: {
    id,
  },
  fetchPolicy: 'no-cache',
});

export const getGlobalSummaryQuery = () => ({
  query: gql`
    query {
      GlobalValueEntityList {
        content {
          summary
        }
      }
    }
  `,
  fetchPolicy: 'no-cache',
});

export const getFiltersTemplateQuery = () => ({
  query: gql`
    query {
      GlobalValueEntityList {
        content {
          filters
        }
      }
    }
  `,
  fetchPolicy: 'no-cache',
});

export const getDashboardChartInitialParametersQuery = (
  user,
  mode,
  workspace
) => ({
  query: gql`
    query ($user: String, $mode: String, $workspace: String) {
      UsersDashboardSettingsEntityList(
        qfilter: {
          key: "uuser"
          value: $user
          operator: equals
          combinator: AND
          next: {
            key: "modeIsDashboard"
            value: $mode
            operator: equals
            combinator: AND
            next: {
              key: "modeIsWorkspace"
              value: $workspace
              operator: equals
            }
          }
        }
      ) {
        content {
          isGraphRule
        }
      }
      RefRulesMonitorEntityList {
        content {
          ruleCode
          ruleGroup
          ruleName
        }
      }
      DealsImportMonitorEntityList(paginator: { size: 1, page: 1 }) {
        content {
          client
          ticker
          dealDtime(OrderBy: DESC)
        }
      }
      RefIssuesMonitorEntityList(paginator: { size: 1, page: 1 }) {
        content {
          ticker
        }
      }
    }
  `,
  variables: { user, mode, workspace },
  fetchPolicy: 'no-cache',
});

export const getRuleDetailsQuery = () => ({
  query: gql`
    query {
      RefRulesMonitorEntityList {
        content {
          ruleCode
          ruleGroup
          ruleName
        }
      }
    }
  `,
  fetchPolicy: 'no-cache',
});

export const getRuleCodesQuery = () => ({
  query: gql`
    query {
      RefRulesMonitorEntityList {
        content {
          ruleCode
        }
      }
    }
  `,
  fetchPolicy: 'no-cache',
});

export const getWatchListByIdQuery = (id) => ({
  query: gql`
    query ($id: UUID) {
      WatchListMonitorEntity(id: $id) {
        id
        name
        refCounterpartyEntityList {
          counterpartyId
          internalClient
          investorType
          counterpartyType
          counterpartyGroupId
          affiliatedGroup
          listBlacklist
          listSanctions
          listSuspicious
          location
          jurisdiction
          riskRating
          fullName
          previousName
          taxId
          beneficiaries
          ceoName
          ceoEducation
          ceoTaxId
          ceoPassport
          id
          ${GPB_MODE ? '' : 'isInsider'}
        }
      }
    }
  `,
  variables: {
    id,
  },
  fetchPolicy: 'no-cache',
});

export const getWatchListsByClientQuery = (client, workspace) => ({
  query: gql`
    query ($client: String, $workspace: String) {
      WatchListMonitorEntityList(
        qfilter: {
          key: "workspace"
          value: $workspace
          operator: equals
          combinator: AND
          next: {
            key: "refCounterpartyEntityList.counterpartyId"
            value: $client
            operator: IN
          }
        }
        paginator: { page: 1, size: 20 }
      ) {
        content {
          name
        }
      }
    }
  `,
  variables: {
    client,
    workspace,
  },
  fetchPolicy: 'no-cache',
});

export const getClientCommentsByWatchListQuery = (name, workspace) => ({
  query: gql`
    query ($name: String, $workspace: String) {
      WatchListMonitorEntityList(
        qfilter: {
          key: "workspace"
          value: $workspace
          operator: equals
          combinator: AND
          next: { key: "name", value: $name, operator: equals }
        }
        paginator: { page: 1, size: 1 }
      ) {
        content {
          watchListRefCounterparty {
            id
            counterpartyId
            comment
          }
        }
      }
    }
  `,
  variables: {
    name,
    workspace,
  },
  fetchPolicy: 'no-cache',
});

export const getClientsByWatchListQuery = (name, workspace) => ({
  query: gql`
    query ($name: String, $workspace: String) {
      WatchListMonitorEntityList(
        qfilter: {
          key: "workspace"
          value: $workspace
          operator: equals
          combinator: AND
          next: { key: "name", value: $name, operator: equals }
        }
        paginator: { page: 1, size: 1 }
      ) {
        content {
          id
          name
          refCounterpartyEntityList {
            counterpartyId
            investorType
            counterpartyType
            counterpartyGroupId
            affiliatedGroup
            listBlacklist
            listSanctions
            listSuspicious
            location
            jurisdiction
            riskRating
            fullName
            previousName
            taxId
            beneficiaries
            ceoName
            ceoEducation
            ceoTaxId
            ceoPassport
            ${GPB_MODE ? '' : 'isInsider'}
          }
        }
      }
    }
  `,
  variables: {
    name,
    workspace,
  },
  fetchPolicy: 'no-cache',
});

export const getCounterpartyQuery = (id) => ({
  query: gql`
    query ($id: String!) {
      RefCounterpartyMonitorEntityList(
        qfilter: { key: "counterpartyId", value: $id, operator: equals }
      ) {
        content {
          id
          affiliatedGroup
          beneficiaries
          ceoEducation
          ceoName
          ceoPassport
          ceoTaxId
          counterpartyGroupId
          counterpartyId
          counterpartyType
          country
          email01
          email02
          fullName
          investorType
          jurisdiction
          listBlacklist
          listSanctions
          listSuspicious
          location
          previousName
          regNumber
          relatedIndividual
          relatedLegalEntity
          riskRating
          taxId
          phone01
          phone02
          isInsider
        }
      }
    }
  `,
  variables: { id },
  fetchPolicy: 'no-cache',
});

export const getClientVisualizationDataQuery = (id) => ({
  query: gql`
    query ($id: String!) {
      RefCounterpartyMonitorEntityList(
        qfilter: { key: "counterpartyId", value: $id, operator: equals }
      ) {
        content {
          fullName
          beneficiaries
          isInsider
          listBlacklist
          listSanctions
          internalClient
        }
      }
    }
  `,
  variables: { id },
  fetchPolicy: 'no-cache',
});

export const getCounterpartyCommentQuery = (id, workspace) => ({
  query: gql`
    query ($workspace: String, $id: String!) {
      RefCounterpartyCommentEntityList(
        qfilter: {
          key: "counterpartyId"
          value: $id
          operator: equals
          combinator: AND
          next: { key: "workSpace", value: $workspace, operator: equals }
        }
      ) {
        content {
          comment
        }
      }
    }
  `,
  variables: { id, workspace },
  fetchPolicy: 'no-cache',
});

export const getWatchListCommentsByCounterpartyIdQuery = (id, workspace) => ({
  query: gql`
    query ($id: String!, $workspace: String!) {
      WatchListRefCounterpartyEntityList(
        qfilter: {
          key: "counterpartyId"
          value: $id
          operator: equals
          combinator: AND
          next: {
            key: "watchList.workspace"
            value: $workspace
            operator: equals
          }
        }
      ) {
        content {
          comment
          watchList {
            name
            comment
          }
        }
      }
    }
  `,
  variables: {
    id,
    workspace,
  },
  fetchPolicy: 'no-cache',
});

export const getClientStatisticsQuery = (workspace, client) => ({
  query: gql`
    query ($workspace: String, $client: String) {
      ClientStatisticEntityList(
        qfilter: {
          key: "workSpace"
          value: $workspace
          operator: equals
          combinator: AND
          next: { key: "client", value: $client, operator: equals }
        }
      ) {
        content {
          countIncident
          countIncidentTrue
          countIncidentFalse
          countSignals
          countCase
          countCaseCritical
          topTicker
        }
      }
    }
  `,
  variables: { workspace, client },
  fetchPolicy: 'no-cache',
});

export const getIncidentIdsByClientQuery = (client, workspace) => ({
  query: gql`
    query ($client: String, $workspace: String) {
      IncidentReportRowMonitorEntityList(
        qfilter: {
          key: "client"
          value: $client
          operator: SIMILAR
          combinator: AND
          next: { key: "workSpace", value: $workspace, operator: equals }
        }
      ) {
        content {
          id
        }
      }
    }
  `,
  variables: {
    client: `(%,)?(${addPatternEscapeChars(client)})(,%)?`,
    workspace,
  },
  fetchPolicy: 'no-cache',
});

export const getIncidentsByClientQuery = (client, workspace) => ({
  query: gql`
    query ($client: String, $workspace: String) {
      IncidentReportRowMonitorEntityList(
        qfilter: {
          key: "client"
          value: $client
          operator: SIMILAR
          combinator: AND
          next: { key: "workSpace", value: $workspace, operator: equals }
        }
      ) {
        content {
          id
          ruleGroup
          ruleId
          modeIncident
        }
      }
    }
  `,
  variables: { client: `(%,)?(${client})(,%)?`, workspace },
  fetchPolicy: 'no-cache',
});

export const getInsiderQuery = (id) => ({
  query: gql`
    query ($id: UUID!) {
      RefInsidersMonitorEntity(id: $id) {
        accessLevel
        insiderId
        taxId
        companyId
        idDocumentSerialNumber
        dateIn
        reasonIn
        reasonOut
        companyName
        dateOut
        dateInCo
        dateOutCo
        isEmployee
        position
        department
        insiderFullName
        issuerTaxId
        birthDate
        birthPlace
        idDocumentDateOfIssue
        idDocumentOldSerialNumber
        createNoteDate
        modifiedDate
        idDocumentIssuedBy
        insiderType
        country
        idClientKey
        reasonInText
        reasonOutText
      }
    }
  `,
  variables: { id },
  fetchPolicy: 'no-cache',
});

export const getStaffQuery = (staffId) => ({
  query: gql`
    query ($staffId: String) {
      RefStaffEntityList(
        qfilter: { key: "staffId", operator: equals, value: $staffId }
      ) {
        content {
          id
          staffId
          fullName
          division
          accessLevelTafs
          joined
          roleInternalInsider
          roleUserTafs
          roleAlm
          roleBackOffice
          roleMiddleOffice
          roleSales
          roleTrader
          roleUser
          position
          grade
          location
          riskRating
          dateOfBirth
          placeOfBirth
          drivingLicense
          education
          previousName
          mobileNumber
          passportDomestic
          passportForeign
          previousEmployer
          taxId
          workphoneNumber
          experienceYearsBackOffice
          experienceYearsMiddleOffice
          experienceYearsTrading
          holidayLastDate
          holidayLast3YearsAverage
          keyFunctions
          comment
          hrComment
          shortName
        }
      }
    }
  `,
  variables: { staffId },
  fetchPolicy: 'no-cache',
});

export const getCounterpartyAffiliatedReport = (counterpartyId) => ({
  query: gql`
    query ($counterpartyId: String) {
      RefCounterpartyAffiliatedGroupEntityList(
        qfilter: {
          key: "counterpartyId"
          operator: equals
          value: $counterpartyId
        }
      ) {
        content {
          id
          counterpartyId
          taxId
          ceoTaxId
          fullName
          previousName
          ceoName
          ceoDateOfBirth
          ceoPassport
          email01
          email02
          phone01
          phone02
          agByTaxIdTaxId
          agByTaxIdCeoTaxId
          agByCeoTaxIdTaxId
          agByCeoTaxIdCeoTaxId
          agByTaxId
          agByTaxIdSize
          agByFullNameFullName
          agByPreviousNameFullName
          agByCeoNameFullName
          agByFullNamePreviousName
          agByPreviousNamePreviousName
          agByCeoNamePreviousName
          agByFullNameCeoName
          agByPreviousNameCeoName
          agByCeoNameCeoName
          agByName
          agByNameSize
          agByDateOfBirth
          agByDateOfBirthSize
          agByCeoPassport
          agByCeoPassportSize
          agByEmail01Email01
          agByEmail02Email01
          agByEmail01Email02
          agByEmail02Email02
          agByEmail
          agByEmailSize
          agByPhone01Phone01
          agByPhone02Phone01
          agByPhone01Phone02
          agByPhone02Phone02
          agByPhone
          agByPhoneSize
          affiliatedLevel3
          affiliatedLevel2
          affiliatedLevel1Strong
          affiliatedByDealsLevel3
          affiliatedByDealsLevel2
          affiliatedByDealsLevel1Strong
          affiliatedAllFilters
          affiliatedCustom
          affiliatedByIp
          affiliatedByMac
          affiliatedByComputerSid
          affiliatedByConnections
          affiliatedByTrueIncidentsCompliance
          affiliatedByTrueAndFalseIncidentsCompliance
          affiliatedByTrueIncidentsIb
          affiliatedByTrueAndFalseIncidentsIb
        }
      }
    }
  `,
  variables: {
    counterpartyId,
  },
  fetchPolicy: 'no-cache',
});

export const getOrderIdQuery = (ordersUniqueHash) => ({
  query: gql`
    query ($ordersUniqueHash: String!) {
      OrdersImportMonitorEntityList(
        qfilter: {
          key: "ordersUniqueHash"
          value: $ordersUniqueHash
          operator: equals
        }
      ) {
        content {
          id
        }
      }
    }
  `,
  variables: { ordersUniqueHash },
  fetchPolicy: 'no-cache',
});

export const getDealIdQuery = (dealsUniqueHash) => ({
  query: gql`
    query ($dealsUniqueHash: String!) {
      DealsImportMonitorEntityList(
        qfilter: {
          key: "dealsUniqueHash"
          value: $dealsUniqueHash
          operator: equals
        }
      ) {
        content {
          id
          dealIdVenue
        }
      }
    }
  `,
  variables: { dealsUniqueHash },
  fetchPolicy: 'no-cache',
});

export const getIncidentSerialIdQuery = (idList) => ({
  query: gql`
    query ($id: String!) {
      IncidentReportRowMonitorEntityList(
        qfilter: { key: "id", operator: IN, value: $id }
      ) {
        content {
          id
          serialId
          modeIncident
          workSpace
        }
      }
    }
  `,
  variables: { id: idList },
  fetchPolicy: 'no-cache',
});

export const getGlobalTickersAnalyticQuery = (user) => ({
  query: gql`
    query ($user: String!) {
      UsersRefRulesAnalyticSettingsEntityList(
        qfilter: { key: "uuser", value: $user, operator: equals }
      ) {
        content {
          tickers
          clients
          isMarketMaker
        }
      }
    }
  `,
  variables: { user },
  fetchPolicy: 'no-cache',
});

export const getGlobalTickersScheduleQuery = (workspace) => ({
  query: gql`
    query ($workspace: String!) {
      UsersRefRulesScheduleSettingsEntityList(
        qfilter: { key: "workSpace", value: $workspace, operator: equals }
      ) {
        content {
          tickers
          clients
          isMarketMaker
        }
      }
    }
  `,
  variables: { workspace },
  fetchPolicy: 'no-cache',
});

export const getIssuesByIdListQuery = (idList) => ({
  query: gql`
    query ($idList: String!) {
      RefIssuesMonitorEntityList(
        qfilter: { key: "id", value: $idList, operator: IN }
      ) {
        content {
          ticker
          venue
          asset
          assetClass
          businessLine
          placementDate
          listingDate
          maturity
          issuer
          secnumber
          currencyContract
          faceValue
          floatIssuedA
          floatIssuedC
          isin
          lot
          liquidity
          secShortName
          secFullName
          issuerTaxId
          issuerId
          boardidMarketprice
          boardidPrimary
          tickerBloomberg
          listingLevel
          comment
          couponAmount
          couponDateNext
          couponDuration
          couponRate
          couponsPerYear
          maturityEarly
          id
        }
      }
    }
  `,
  variables: { idList },
  fetchPolicy: 'no-cache',
});

export const getCounterpartiesByIdQuery = (clients) => ({
  query: gql`
    query ($clients: String!) {
      RefCounterpartyMonitorEntityList(
        qfilter: { key: "counterpartyId", value: $clients, operator: IN }
      ) {
        content {
          counterpartyId
          investorType
          counterpartyType
          counterpartyGroupId
          affiliatedGroup
          listBlacklist
          listSanctions
          listSuspicious
          location
          jurisdiction
          riskRating
          fullName
          previousName
          taxId
          beneficiaries
          ceoName
          ceoEducation
          ceoTaxId
          ceoPassport
          id
        }
      }
    }
  `,
  variables: { clients },
  fetchPolicy: 'no-cache',
});

export const getRuleGroupFineTuningsSignalQuery = (
  ruleGroup,
  workSpace,
  user
) => ({
  query: gql`
    query ($ruleGroup: String!, $workSpace: String!) {
      RefRulesMonitorEntityList(
        qfilter: {
          key: "ruleGroup"
          operator: equals
          value: $ruleGroup
          combinator: AND
          next: {
            operator: equals
            key: "tuningsSignal.workSpace"
            value: $workSpace
          }
        }
      ) {
        content {
          id
          ruleCode
          ruleName
          tuningsSignal {
            id
            priority
            params
            __typename
          }
          __typename
        }
        __typename
      }
    }
  `,
  variables: {
    ruleGroup,
    workSpace,
  },
  fetchPolicy: 'no-cache',
});

export const getRuleGroupParamsValidationQuery = (ruleGroup) => ({
  query: gql`
    query ($ruleGroup: String!) {
      RefRulesEntityList(
        qfilter: { key: "ruleGroup", operator: equals, value: $ruleGroup }
      ) {
        content {
          jsonParamsValidation
        }
      }
    }
  `,
  variables: {
    ruleGroup,
  },
  fetchPolicy: 'no-cache',
});

export const getSignalInfoForAnalyticIBQuery = (id) => ({
  query: gql`
    query ($id: UUID!) {
      IncidentReportRowMonitorEntity(id: $id) {
        signalIb {
          message
          volumeDeviation
          priceDeviation
          direction
          idVenue
          clientSubdet
          clientDet
          client
          firmId
          boardId
          ticker
          signalType
          critName
          reportDate
          signalAlgoSourceEnum
        }
        __typename
      }
    }
  `,
  variables: {
    id,
  },
  fetchPolicy: 'no-cache',
});

export const getSignalInfoForAnalyticComplianceQuery = (id) => ({
  query: gql`
    query ($id: UUID!) {
      IncidentReportRowMonitorEntity(id: $id) {
        signalCompliance {
          message
          volumeDeviation
          priceDeviation
          direction
          idVenue
          clientSubdet
          clientDet
          client
          firmId
          boardId
          ticker
          signalType
          critName
          reportDate
          signalAlgoSourceEnum
        }
        __typename
      }
    }
  `,
  variables: {
    id,
  },
  fetchPolicy: 'no-cache',
});

export const getMMSignalInfoForAnalyticIBQuery = (id) => ({
  query: gql`
    query ($id: UUID!) {
      IncidentReportRowMonitorEntity(id: $id) {
        signalMarketMakerReportIb {
          businessNonStandartDealsVolumeShare60Days
          businessNonStandartDealsVolumeShare
          businessNonStandartDealsVolume
          businessNonStandartDealsNumberShare60Days
          businessNonStandartDealsNumberShare
          businessNonStandartDealsNumber
          regNonStandartDealsVolumeShare60Days
          regNonStandartDealsVolumeShare
          regNonStandartDealsVolume
          regNonStandartDealsNumberShare60Days
          regNonStandartDealsNumberShare
          regNonStandartDealsNumber
          nonStandartDealsVolumeShare60Days
          nonStandartDealsVolumeShare
          nonStandartDealsVolume
          nonStandartDealsNumberShare60Days
          nonStandartDealsNumberShare
          nonStandartDealsNumber
          baseTicker
          maturity
          tickerExpNumber
          period
          boardId
          ticker
          registration
          client
          account
          firmId
          business
          dealDate
          assetClass
          reportType
          __typename
        }
        __typename
      }
    }
  `,
  variables: {
    id,
  },
  fetchPolicy: 'no-cache',
});

export const getMMSignalInfoForAnalyticComplianceQuery = (id) => ({
  query: gql`
    query ($id: UUID!) {
      IncidentReportRowMonitorEntity(id: $id) {
        signalMarketMakerReportCompliance {
          businessNonStandartDealsVolumeShare60Days
          businessNonStandartDealsVolumeShare
          businessNonStandartDealsVolume
          businessNonStandartDealsNumberShare60Days
          businessNonStandartDealsNumberShare
          businessNonStandartDealsNumber
          regNonStandartDealsVolumeShare60Days
          regNonStandartDealsVolumeShare
          regNonStandartDealsVolume
          regNonStandartDealsNumberShare60Days
          regNonStandartDealsNumberShare
          regNonStandartDealsNumber
          nonStandartDealsVolumeShare60Days
          nonStandartDealsVolumeShare
          nonStandartDealsVolume
          nonStandartDealsNumberShare60Days
          nonStandartDealsNumberShare
          nonStandartDealsNumber
          baseTicker
          maturity
          tickerExpNumber
          period
          boardId
          ticker
          registration
          client
          account
          firmId
          business
          dealDate
          assetClass
          reportType
          __typename
        }
        __typename
      }
    }
  `,
  variables: {
    id,
  },
  fetchPolicy: 'no-cache',
});

export const getOverlapIncidentsQuery = (incidents) => ({
  query: gql`
    query ($incidents: String!) {
      IncidentReportRowMonitorEntityList(
        qfilter: { key: "id", value: $incidents, operator: IN }
      ) {
        content {
          id
          modeIncident
          incidentEndDtime(OrderBy: ASC)
          ruleId
          workSpace
        }
      }
    }
  `,
  variables: { incidents },
  fetchPolicy: 'no-cache',
});

export const getUnprocessedIncidentsChartDataQuery = (id) => ({
  query: gql`
    query ($id: UUID, $reportId: String) {
      ReportEntity(id: $id) {
        overlapIncidentOldVisualData {
          id
          reportDate
          status
          ruleId
          hardness
          mode
        }
      }
      UnusedIncidentEntityList(
        paginator: { page: 1, size: 1 }
        qfilter: { key: "reportId", value: $reportId, operator: equals }
      ) {
        content {
          hardness
        }
      }
    }
  `,
  variables: { id, reportId: id },
  fetchPolicy: 'no-cache',
});

export const getAvailableRuleGroupsQuery = () => ({
  query: gql`
    query {
      WorkSpaceEntityList {
        content {
          workSpaceName
          refRulesMonitorEntities {
            ruleGroup
          }
        }
      }
    }
  `,
  fetchPolicy: 'no-cache',
});

export const getIncidentJudgementQuery = (id) => ({
  query: gql`
    query ($id: UUID) {
      StatisticsIncidentEntity(id: $id) {
        description
      }
    }
  `,
  variables: {
    id,
  },
  fetchPolicy: 'no-cache',
});

export const getRankingIncidentsQuery = (filter) => {
  return {
    operationName: null,
    variables: {
      filter: filter,
    },
    query:
      'query ($filter: qfilter) { IncidentReportRowMonitorEntityList(qfilter: $filter) { content {id, ruleId}}}',
  };
};

export const getMarketInputsByRuleQuery = (ruleCode) => ({
  query: gql`
    query ($ruleCode: String!) {
      IncidentReportRowMonitorEntityList(
        qfilter: { key: "ruleId", value: $ruleCode, operator: equals }
      ) {
        content {
          ticker
          client
          incidentEndDtime(OrderBy: DESC)
        }
      }
    }
  `,
  variables: { ruleCode },
  fetchPolicy: 'no-cache',
});

export const getOverlapClientsChartDataQuery = (reportId) => ({
  query: gql`
    query ($reportId: String!) {
      OverlapCounterpartyEntityList(
        qfilter: { key: "reportId", value: $reportId, operator: equals }
      ) {
        content {
          client
          idIncidentIb
          idIncidentCompliance
        }
      }
    }
  `,
  variables: {
    reportId,
  },
  fetchPolicy: 'no-cache',
});

export const getOverlapDealsChartDataQuery = (reportId) => ({
  query: gql`
    query ($reportId: String!) {
      OverlapDealsEntityList(
        qfilter: { key: "reportId", value: $reportId, operator: equals }
      ) {
        content {
          idDeal
          dealIdVenue
          idIncidentIb
          idIncidentCompliance
        }
      }
    }
  `,
  variables: {
    reportId,
  },
  fetchPolicy: 'no-cache',
});

export const getOverlapOrdersChartDataQuery = (reportId) => ({
  query: gql`
    query ($reportId: String!) {
      OverlapOrdersEntityList(
        qfilter: { key: "reportId", value: $reportId, operator: equals }
      ) {
        content {
          idOrder
          orderIdVenue
          idIncidentIb
          idIncidentCompliance
        }
      }
    }
  `,
  variables: {
    reportId,
  },
  fetchPolicy: 'no-cache',
});

export const getCounterpartyUuidQuery = (id) => ({
  query: gql`
    query ($id: String!) {
      RefCounterpartyMonitorEntityList(
        qfilter: { key: "counterpartyId", value: $id, operator: equals }
      ) {
        content {
          id
        }
      }
    }
  `,
  variables: { id },
  fetchPolicy: 'no-cache',
});

export const getCounterpartyDescriptionQuery = (id) => ({
  query: gql`
    query ($id: String!) {
      RefCounterpartyMonitorEntityList(
        qfilter: { key: "counterpartyId", value: $id, operator: equals }
      ) {
        content {
          descriptionIb
          descriptionCompliance
        }
      }
    }
  `,
  variables: { id },
  fetchPolicy: 'no-cache',
});

export const getClientRiskCoefficientQuery = (id) => ({
  query: gql`
    query ($id: String!) {
      RiskModelClientEntityList(
        qfilter: { key: "client", value: $id, operator: equals }
      ) {
        content {
          clientRiskCoefficient
        }
      }
    }
  `,
  variables: { id },
  fetchPolicy: 'no-cache',
});

export const getReferenceFilesQuery = (relatedId, workspace) => ({
  query: gql`
    query ($relatedId: String!, $workspace: String!) {
      ReferenceFilesEntityList(
        qfilter: {
          key: "relatedId"
          value: $relatedId
          operator: equals
          combinator: AND
          next: { key: "workSpace", value: $workspace, operator: equals }
        }
      ) {
        content {
          id
          name
        }
      }
    }
  `,
  variables: { relatedId, workspace },
  fetchPolicy: 'no-cache',
});

export const getKafkaMessageJournalAnalyticQuery = () => ({
  query: gql`
    query {
      KafkaMessageJournalEntityList {
        content {
          key
          startReportDate
          endReportDate
          isSuccess
          startParsingTime
          finishParsingTime
          countDoubleStringBD
          countUnloadStringByLogic
          countDoubleStringAllLogic
          countLoadStringLogic1
          countLoadStringLogic2
          countLoadStringLogic3
          countDoubleStringLogic1
          countDoubleStringLogic2
          countDoubleStringLogic3
          tickerListLogic_2
          tickerListUnloadLogic
          priorityList
        }
      }
    }
  `,
  fetchPolicy: 'no-cache',
});

export const getKafkaMessageJournalSecAdminQuery = () => ({
  query: gql`
    query {
      KafkaMessageJournalEntityList {
        content {
          key
          startReportDate
          endReportDate
          isFinish
          isSuccess
          performance
          startParsingTime
          finishParsingTime
          countReceivedString
          countLoadString
          countDoubleStringMessage
          countLoadStringLogic1
          countLoadStringLogic2
          countDoubleStringLogic1
          countDoubleStringLogic2
          threshholdCount
          threshholdTime
          isCorrectThreshholdTime
          tickerListUnloadLogic
          countDoubleStringAllLogic
        }
      }
    }
  `,
  fetchPolicy: 'no-cache',
});

export const getImportedFilesAnalyticQuery = () => ({
  query: gql`
    query {
      ImportedFilesEntityList {
        content {
          key
          startReportDate
          endReportDate
          isSuccess
          startParsingTime
          finishParsingTime
          countLoadStringLogic1
          countLoadStringLogic2
          countLoadStringLogic3
          countDoubleStringLogic1
          countDoubleStringLogic2
          countDoubleStringLogic3
          countTimestampOrderbook
        }
      }
    }
  `,
  fetchPolicy: 'no-cache',
});

export const getImportedFilesSecAdminQuery = () => ({
  query: gql`
    query {
      ImportedFilesEntityList {
        content {
          key
          startReportDate
          endReportDate
          isFinish
          isSuccess
          performance
          startParsingTime
          finishParsingTime
          countReceivedString
          countLoadString
          countDoubleStringMessage
          countLoadStringLogic1
          countLoadStringLogic2
          countDoubleStringLogic1
          countDoubleStringLogic2
          threshholdCount
          threshholdTime
          isCorrectThreshholdTime
        }
      }
    }
  `,
  fetchPolicy: 'no-cache',
});

export const getInformationUploadAnalyticQuery = () => ({
  query: gql`
    query {
      InformationUploadingDataEntityList {
        content {
          key
          startReportDate
          endReportDate
          isSuccess
          startParsingTime
          finishParsingTime
          countDoubleStringBD
          countUnloadStringByLogic
          countDoubleStringAllLogic
          countLoadStringLogic1
          countLoadStringLogic2
          countLoadStringLogic3
          countDoubleStringLogic1
          countDoubleStringLogic2
          countDoubleStringLogic3
          tickerListLogic_2
          tickerListUnloadLogic
          priorityList
          countTimestampOrderbook
        }
      }
    }
  `,
  fetchPolicy: 'no-cache',
});

export const getInformationUploadSecAdminQuery = () => ({
  query: gql`
    query {
      InformationUploadingDataEntityList {
        content {
          key
          startReportDate
          endReportDate
          isFinish
          isSuccess
          performance
          startParsingTime
          finishParsingTime
          typeSource
          countReceivedString
          countLoadString
          countDoubleStringMessage
          countLoadStringLogic1
          countLoadStringLogic2
          countDoubleStringLogic1
          countDoubleStringLogic2
          threshholdCount
          threshholdTime
          isCorrectThreshholdTime
        }
      }
    }
  `,
  fetchPolicy: 'no-cache',
});

export const getDealsClientTickerVenueByIdQuery = (id) => ({
  query: gql`
    query ($id: UUID!) {
      DealsImportMonitorEntity(id: $id) {
        dealDtime
        ticker
        venue
        client
      }
    }
  `,
  variables: {
    id,
  },
  fetchPolicy: 'no-cache',
});

export const getOrdersClientTickerVenueByIdQuery = (id) => ({
  query: gql`
    query ($id: UUID!) {
      OrdersImportMonitorEntity(id: $id) {
        dTime
        ticker
        venue
        client
      }
    }
  `,
  variables: {
    id,
  },
  fetchPolicy: 'no-cache',
});

export const getDealsClientTickerVenuerByIdVenueQuery = (dealId) => ({
  query: gql`
    query ($dealId: String!) {
      DealsImportMonitorEntityList(
        qfilter: { key: "dealIdVenue", value: $dealId, operator: equals }
      ) {
        content {
          dealDtime
          ticker
          venue
          client
        }
      }
    }
  `,
  variables: {
    dealId,
  },
  fetchPolicy: 'no-cache',
});

export const getOrdersClientTickerVenuerByIdVenueQuery = (orderId) => ({
  query: gql`
    query ($orderId: String!) {
      OrdersImportMonitorEntityList(
        qfilter: { key: "idVenue", value: $orderId, operator: equals }
      ) {
        content {
          dTime
          ticker
          venue
          client
        }
      }
    }
  `,
  variables: {
    orderId,
  },
  fetchPolicy: 'no-cache',
});

export const getClientConclusionQuery = (client) => ({
  query: gql`
    query ($client: String) {
      ClientConclusionEntityList(
        qfilter: { key: "relatedClient", value: $client, operator: equals }
      ) {
        content {
          id
          relatedClient
          createdAt
          user
          comment
          workSpace
        }
      }
    }
  `,
  variables: {
    client,
  },
  fetchPolicy: 'no-cache',
});

export const getRiskModelTuningsQuery = (workspace) => ({
  query: gql`
    query ($workspace: String) {
      FineTuningRiskMatrixEntityList(
        qfilter: { key: "workSpace", value: $workspace, operator: equals }
      ) {
        content {
          id
          tunings
          wCoefficient
        }
      }
    }
  `,
  variables: {
    workspace,
  },
  fetchPolicy: 'no-cache',
});

export const getRowSettingsQuery = (workspace, entity) => ({
  query: gql`
    query ($workspace: String!, $entity: String!) {
      RowColorsEntityList(
        qfilter: {
          key: "entityType"
          value: $entity
          operator: equals
          combinator: AND
          next: { key: "workSpace", value: $workspace, operator: equals }
        }
      ) {
        content {
          settings
        }
      }
    }
  `,
  variables: {
    workspace,
    entity,
  },
  fetchPolicy: 'no-cache',
});

export const getIncidentsByOrderIdQuery = (id, ws) => ({
  query: gql`
    query ($id: UUID!) {
      OrdersImportMonitorEntity(id: $id) {
        incidents {
          id
          mode
          ruleGroup
          ruleId
          workSpace
        }
      }
    }
  `,
  variables: {
    id,
    ws,
  },
  fetchPolicy: 'no-cache',
});

export const getIncidentsByDealIdQuery = (id, ws) => ({
  query: gql`
    query ($id: UUID!) {
      DealsImportMonitorEntity(id: $id) {
        incidents {
          id
          mode
          ruleGroup
          ruleId
          workSpace
        }
      }
    }
  `,
  variables: {
    id,
    ws,
  },
  fetchPolicy: 'no-cache',
});

export const getCrossDealInfoQuery = (id, ws) => ({
  query: gql`
    query ($id: UUID!) {
      CrossDealsReportEntity(id: $id) {
        boardId
        dealType
        dealidvenue
        dealdtime
        ticker
        isin
        dealVolumeasset
        settlementcurrency
        dealPrice
        dealVolumebase
        timeDistanceSeconds
        clientBuy
        clientSell
        internalClientBuy
        internalClientSell
        isStaffBuy
        isStaffSell
        orderIdVenueBuy
        orderIdVenueSell
        orderDtimeBuy
        orderDtimeSell
        orderVolumeassetBuy
        orderVolumeassetSell
        orderExecutedPartBuy
        orderExecutedPartSell
        orderVolumeassetMatchedBuy
        orderVolumeassetMatchedSell
        numberDealsBuy
        numberDealsSell
        orderPriceBuy
        orderPriceSell
        orderVolumebaseBuy
        orderVolumebaseSell
        orderPlacementChannelBuy
        orderPlacementChannelSell
        ismarketmakerBuy
        ismarketmakerSell
        statusBuy
        statusSell
        serviceLocationBuy
        serviceLocationSell
        connectionBuy
        connectionSell
        secfullname
      }
    }
  `,
  variables: {
    id,
    ws,
  },
  fetchPolicy: 'no-cache',
});

export const getClientRiskProfileQuery = (client, fields) => ({
  query: gql`
  query {
    RiskModelClientEntityList( qfilter: {
      operator: equals,
      key: "client",
      value: "${client}"
    }) {
      content {
        ${fields}
      }
    }
  }
  `,
  fetchPolicy: 'no-cache',
});

export const getCaseMetricsQuery = (caseId) => ({
  query: gql`
    query ($caseId: String!) {
      CasesMetricsEntityList(
        qfilter: { key: "caseId", value: $caseId, operator: equals }
      ) {
        content {
          incidentList
          tickerList
          internalOfficerList
          clientList
          caseStartDTime
          caseEndDTime
          crossDealsList
          dealsList
          ordersList
          orderVolumeBaseRubTurnover
          orderVolumeBaseRubNet
          volumeBaseRubTurnover
          volumeBaseRubNet
          probabilityTruthAvg
          trueIncidentList
          riskIncidentList
          riskClientList
        }
      }
    }
  `,
  variables: {
    caseId,
  },
  fetchPolicy: 'no-cache',
});

export const getSignalsByClientQuery = (clientId, workspace) => ({
  query: gql`
    query ($workspace: String!, $clientId: String!) {
      IncidentReportRowMonitorEntityList(
        qfilter: {
          operator: equals
          key: "modeIncident"
          value: "SIGNAL"
          combinator: AND
          next: {
            key: "workSpace"
            value: $workspace
            operator: equals
            combinator: AND
            next: { key: "client", value: $clientId, operator: SIMILAR }
          }
        }
      ) {
        content {
          id
          ruleGroup
          ruleId
        }
      }
    }
  `,
  variables: {
    clientId: `(%,)?(${addPatternEscapeChars(clientId)})(,%)?`,
    workspace,
  },
  fetchPolicy: 'no-cache',
});

export const getSystemParamsQuery = (name) => ({
  query: gql`
    query ($name: String!) {
      SystemParamsEntityList(
        qfilter: { operator: equals, key: "name", value: $name }
      ) {
        content {
          value
        }
      }
    }
  `,
  variables: {
    name,
  },
  fetchPolicy: 'no-cache',
});

export const getCaseInfoQuery = (caseId) => ({
  query: gql`
    query ($caseId: String!) {
      CasesMetricsEntityList(
        qfilter: { key: "caseId", value: $caseId, operator: equals }
      ) {
        content {
          tickerList
          clientList
          caseStartDTime
          caseEndDTime
        }
      }
    }
  `,
  variables: {
    caseId,
  },
  fetchPolicy: 'no-cache',
});

export const getSwapDealMainIdQuery = (venueId) => ({
  query: gql`
    query ($venueId: String!) {
      DealsImportMonitorEntityList(
        qfilter: {
          key: "dealIdVenue"
          value: $venueId
          operator: equals
          combinator: AND
          next: { key: "dealtype", value: "swap", operator: equals }
        }
      ) {
        content {
          id
        }
      }
    }
  `,
  variables: {
    venueId,
  },
  fetchPolicy: 'no-cache',
});

export const getSwapDealLegsQuery = (venueId) => ({
  query: gql`
    query ($venueId: String!) {
      DealsImportMonitorEntityList(
        qfilter: { key: "dealidvenuelinked", value: $venueId, operator: equals }
      ) {
        content {
          id
          dealIdVenue
          ticker
          dealmode
          boardId
          dealDtime
          vdateAsset
          contractdirection
          volumeasset
          volumeassetUnits
          volumeBase
          price
          refIssues {
            secFullName
            secShortName
          }
        }
      }
    }
  `,
  variables: {
    venueId,
  },
  fetchPolicy: 'no-cache',
});

export const getCustomRuleDraftQuery = (id) => ({
  query: gql`
    query ($id: UUID!) {
      DraftCustomRefRulesEntity(id: $id) {
        ruleId
        ruleName
        sql
        version
        comment
        jsonMetricsTemplate
        ruleTranslations
        params
        isDeals
      }
    }
  `,
  variables: {
    id,
  },
  fetchPolicy: 'no-cache',
});

export const getProfilingIncidentQuery = (id) => ({
  query: gql`
    query ($id: UUID!) {
      ProfilingIncident(id: $id) {
        type
        createdDate
        client
        ticker
        venue
        reportDate
        assetClass
        startDTime
        endDTime
        metrics
        profilingHistogram {
          histData
          statData
          averageData
        }
        description
      }
    }
  `,
  variables: { id },
  fetchPolicy: 'no-cache',
});

export const getTickerByProfilingIncidentQuery = (id) => ({
  query: gql`
    query ($id: String) {
      ProfilingIncidentOrdersList(
        paginator: { page: 1, size: 1 }
        qfilter: { key: "incident.id", value: $id, operator: equals }
      ) {
        content {
          order {
            ticker
          }
        }
      }
      ProfilingIncidentDealsList(
        paginator: { page: 1, size: 1 }
        qfilter: { key: "incident.id", value: $id, operator: equals }
      ) {
        content {
          deal {
            ticker
          }
        }
      }
    }
  `,
  variables: {
    id,
  },
  fetchPolicy: 'no-cache',
});