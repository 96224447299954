import { gql } from 'apollo-boost';
import format from 'date-fns/format';

import {
  login,
  logout,
  graphql,
  getUserFilters,
  getAvailableRuleGroups,
  getGlobalSummary,
} from '@tafs/services/api';
import {
  LOGIN_FAIL,
  LOGIN_PENDING,
  LOGIN_SUCCESS,
  LOGOUT_FAIL,
  LOGOUT_SUCCESS,
  SET_REPORT_FILTERS,
  SET_REPORT_END_DATE,
  SET_REPORT_DATES,
  SET_RULE_GROUPS_BY_WORKSPACE,
} from '@tafs/constants/actions';
import { notifyService } from '@tafs/services/notifications';
import { SCHEDULE, INVESTIGATION } from '@tafs/constants/appModes';
import { parsePermissions } from '@tafs/constants/permissions';

const { LITE_MODE } = process.env;

const introspectionQuery = gql`
  query IntrospectionQuery {
    __schema {
      #      queryType {
      #        name
      #      }
      #      mutationType {
      #        name
      #      }
      #      subscriptionType {
      #        name
      #      }
      types {
        ...FullType
      }
      #      directives {
      #        name
      #        description
      #        locations
      #        args {
      #          ...InputValue
      #        }
      #      }
    }
  }

  fragment FullType on __Type {
    kind
    name
    #    description
    fields(includeDeprecated: true) {
      name
      #      description
      #      args {
      #        ...InputValue
      #      }
      type {
        ...TypeRef
      }
      #      isDeprecated
      #      deprecationReason
    }
    #    inputFields {
    #      ...InputValue
    #    }
    #    interfaces {
    #      ...TypeRef
    #    }
    enumValues(includeDeprecated: true) {
      name
      #      description
      #      isDeprecated
      #      deprecationReason
    }
    #    possibleTypes {
    #      ...TypeRef
    #    }
  }

  #  fragment InputValue on __InputValue {
  #    name
  #    description
  #    type {
  #      ...TypeRef
  #    }
  #    defaultValue
  #  }

  fragment TypeRef on __Type {
    kind
    name
    ofType {
      kind
      name
      ofType {
        kind
        name
        ofType {
          kind
          name
          ofType {
            kind
            name
            ofType {
              kind
              name
              ofType {
                kind
                name
                ofType {
                  kind
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const logIn = (username, password) => async (dispatch) => {
  dispatch({ type: LOGIN_PENDING });
  try {
    const user = await login(username, password);
    const introspectionRes = await graphql.query({ query: introspectionQuery });
    const userPermissions = parsePermissions(user.permissions, true);

    const result = {
      username: user.username,
      permissions: userPermissions,
      schema: introspectionRes.data.__schema,
    };

    if (
      userPermissions?.analytic_ib?.schedule?.dashboard?.view ||
      userPermissions?.analytic_ib?.investigation?.dashboard?.view ||
      userPermissions?.analytic_compliance?.schedule?.dashboard?.view ||
      userPermissions?.analytic_compliance?.investigation?.dashboard?.view
    ) {
      getUserFilters(user.username)
        .then((res) => {
          Object.keys(res).forEach((workspace) => {
            Object.keys(res[workspace]).forEach((mode) => {
              dispatch({
                type: SET_REPORT_FILTERS,
                payload: res[workspace][mode].filters,
                mode,
                workspace,
              });
            });

            // if (!LITE_MODE)
            //   dispatch({
            //     type: SET_REPORT_END_DATE,
            //     payload: format(new Date(), 'yyyy-MM-dd'),
            //     mode: SCHEDULE,
            //     workspace,
            //   });

            getGlobalSummary()
              .then(({ startDate, endDate }) =>
                [SCHEDULE, INVESTIGATION].forEach((mode) =>
                  dispatch({
                    type: SET_REPORT_DATES,
                    payload: { startDate, endDate },
                    mode,
                    workspace,
                  })
                )
              )
              .catch(({ message }) => notifyService.error(message));
          });
        })
        .catch(({ message }) => notifyService.error(message));

      getAvailableRuleGroups()
        .then((res) =>
          dispatch({
            type: SET_RULE_GROUPS_BY_WORKSPACE,
            payload: res,
          })
        )
        .catch(({ message }) => notifyService.error(message));
    }

    dispatch({
      type: LOGIN_SUCCESS,
      payload: result,
    });
  } catch (error) {
    let message;
    switch (error) {
      case 503:
        message =
          'Maintenance mode is enabled, please contact your administrator';
        break;
      default:
        message = 'Login error';
    }
    dispatch({ type: LOGIN_FAIL, payload: { message } });
  }
};

export const logOut = () => async (dispatch) =>
  logout()
    .then(() =>
      dispatch({
        type: LOGOUT_SUCCESS,
      })
    )
    .catch(() =>
      dispatch({
        type: LOGOUT_FAIL,
      })
    );
