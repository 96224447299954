import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import { NS } from '@tafs/i18n/i18nextConfig';
import DataTable from '@tafs/components/common/DataTable';
import {
  getKafkaMessageJournal,
  getFtpImportedFiles,
  getInformationUploadingData,
} from '@tafs/services/api';
import { notifyService } from '@tafs/services/notifications';
import LoadingProgress from '@tafs/components/common/LoadingProgress';
import { workspaceObj } from '@tafs/constants/workspaces';
import { fieldTypes } from '@tafs/components/common/DataTable/utils';
import styles from './index.module.css';

const { GPB_MODE, LITE_MODE, PROD_MODE } = process.env;

export default function HealthCheckDialog({ onClose }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState(null);
  const { t } = useTranslation();
  const { workspace } = useSelector((state) => state.app);

  useEffect(() => {
    setIsLoaded(false);
    if (LITE_MODE) {
      getInformationUploadingData(workspace.enum)
        .then((res) => setData(res))
        .catch((err) => notifyService.error(err.message))
        .finally(() => setIsLoaded(true));
    } else {
      Promise.all([
        getKafkaMessageJournal(workspace.enum),
        getFtpImportedFiles(workspace.enum),
        ...(PROD_MODE ? [getInformationUploadingData(workspace.enum)] : []),
      ])
        .then((res) =>
          setData([...res[0], ...res[1], ...(PROD_MODE ? res[2] : [])])
        )
        .catch((err) => notifyService.error(err.message))
        .finally(() => setIsLoaded(true));
    }
  }, [workspace]);

  const getRowStyle = ({ data }) => {
    return (
      !data.isSuccess && {
        backgroundColor: '#EF8D84',
      }
    );
  };

  const columnDefs = [
    {
      field: 'key',
      headerName: `${NS.ENTITIES}:KafkaMessageJournalEntity.key`,
      fieldType: fieldTypes.string,
    },
    ...(PROD_MODE || GPB_MODE
      ? [
          {
            field: 'startReportDate',
            headerName: `${NS.ENTITIES}:KafkaMessageJournalEntity.startReportDate`,
            fieldType: fieldTypes.date,
          },
          {
            field: 'endReportDate',
            headerName: `${NS.ENTITIES}:KafkaMessageJournalEntity.endReportDate`,
            fieldType: fieldTypes.date,
          },
          {
            field: 'isSuccess',
            headerName: `${NS.ENTITIES}:KafkaMessageJournalEntity.isSuccess`,
            fieldType: fieldTypes.enum,
            cellRendererFramework: ({ value }) => (value ? t('Yes') : t('No')),
          },
          {
            field: 'startParsingTime',
            headerName: `${NS.ENTITIES}:KafkaMessageJournalEntity.startParsingTime`,
            fieldType: fieldTypes.date,
          },
          {
            field: 'finishParsingTime',
            headerName: `${NS.ENTITIES}:KafkaMessageJournalEntity.finishParsingTime`,
            fieldType: fieldTypes.date,
          },
        ]
      : []),
    {
      field: 'countDoubleStringBD',
      headerName: `${NS.ENTITIES}:KafkaMessageJournalEntity.countDoubleStringBD`,
      fieldType: fieldTypes.number,
    },
    ...(GPB_MODE
      ? [
          {
            field: 'countUnloadStringByLogic',
            headerName: `${NS.ENTITIES}:KafkaMessageJournalEntity.countUnloadStringByLogic`,
            fieldType: fieldTypes.number,
          },
          {
            field: 'countDoubleStringAllLogic',
            headerName: `${NS.ENTITIES}:KafkaMessageJournalEntity.countDoubleStringAllLogic`,
            fieldType: fieldTypes.number,
          },
          {
            field: 'countLoadStringLogic1',
            headerName: `${NS.ENTITIES}:KafkaMessageJournalEntity.countLoadStringLogic1`,
            fieldType: fieldTypes.number,
          },
          {
            field: 'countLoadStringLogic2',
            headerName: `${NS.ENTITIES}:KafkaMessageJournalEntity.countLoadStringLogic2`,
            fieldType: fieldTypes.number,
          },
          {
            field: 'countLoadStringLogic3',
            headerName: `${NS.ENTITIES}:KafkaMessageJournalEntity.countLoadStringLogic3`,
            fieldType: fieldTypes.number,
            hidden: workspace.enum === workspaceObj.COMPLIANCE.enum,
          },
          {
            field: 'countDoubleStringLogic1',
            headerName: `${NS.ENTITIES}:KafkaMessageJournalEntity.countDoubleStringLogic1`,
            fieldType: fieldTypes.number,
          },
          {
            field: 'countDoubleStringLogic2',
            headerName: `${NS.ENTITIES}:KafkaMessageJournalEntity.countDoubleStringLogic2`,
            fieldType: fieldTypes.number,
          },
          {
            field: 'countDoubleStringLogic3',
            headerName: `${NS.ENTITIES}:KafkaMessageJournalEntity.countDoubleStringLogic3`,
            fieldType: fieldTypes.number,
            hidden: workspace.enum === workspaceObj.COMPLIANCE.enum,
          },
          {
            field: 'tickerListLogic_2',
            headerName: `${NS.ENTITIES}:KafkaMessageJournalEntity.tickerListLogic_2`,
            fieldType: fieldTypes.string,
            truncatable: true,
          },
        ]
      : []),
    {
      field: 'tickerListUnloadLogic',
      headerName: `${NS.ENTITIES}:KafkaMessageJournalEntity.tickerListUnloadLogic`,
      fieldType: fieldTypes.string,
      truncatable: true,
    },
    {
      field: 'priorityList',
      headerName: `${NS.ENTITIES}:KafkaMessageJournalEntity.priorityList`,
      fieldType: fieldTypes.string,
    },
    {
      field: 'countTimestampOrderbook',
      headerName: `${NS.ENTITIES}:ImportedFilesEntity.countTimestampOrderbook`,
      fieldType: fieldTypes.number,
      hidden: workspace.enum === workspaceObj.COMPLIANCE.enum,
    },
  ];

  return (
    <Dialog onClose={onClose} open={true} maxWidth="lg" fullWidth>
      <DialogTitle>{t('Health check')}</DialogTitle>
      <DialogContent>
        {isLoaded ? (
          <div className={styles.tableContainer}>
            <DataTable
              className={styles.table}
              stateKey={`healthCheck_${workspace.enum}`}
              columnDefs={columnDefs.filter((col) => !col.hidden)}
              rowData={data}
              filterToolPanel={true}
              getRowStyle={getRowStyle}
            />
          </div>
        ) : (
          <LoadingProgress />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
