export const enterpriseSummaryReportType = {
  name: 'Enterprise summary',
  id: 'enterpriseSummary',
};
export const rogueTradingReportType = {
  name: 'Rogue trading',
  id: 'rogueTrading',
  filter: { ruleGroup: 'ROGUE_TRADING' },
};
export const antifraudReportType = {
  name: 'Antifraud',
  id: 'antifraud',
  filter: { ruleGroup: 'ANTIFRAUD' },
};
export const marketAbuseReportType = {
  name: 'Market abuse',
  id: 'marketAbuse',
  filter: { ruleGroup: 'MARKET_ABUSE' },
};
export const unusualActivityReportType = {
  name: 'Unusual activity',
  id: 'unusualActivity',
  filter: { ruleGroup: 'UNUSUAL_ACTIVITY' },
};
export const effectivenessReportType = {
  name: 'Effectiveness',
  id: 'effectiveness',
  filter: { ruleGroup: 'EFFECTIVENESS' },
};
export const signalsReportType = {
  name: 'External system signals',
  id: 'externalSystemSignals',
  filter: { ruleGroup: 'SIGNAL' },
};
export const signalsManualReportType = {
  name: 'Signal rules',
  id: 'externalSystemManualSignals',
  filter: { ruleGroup: 'SIGNAL_MANUAL' },
};

export const reportTypes = [
  enterpriseSummaryReportType,
  antifraudReportType,
  marketAbuseReportType,
  unusualActivityReportType,
  rogueTradingReportType,
  effectivenessReportType,
  signalsReportType,
  signalsManualReportType,
];

export const reportTypesObj = reportTypes.reduce((obj, type) => {
  obj[type.id] = type;
  return obj;
}, {});
