import React, { Component } from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { isEqual, isNaN, isObject } from 'lodash';
import { withTranslation } from 'react-i18next';
import FileDownload from 'js-file-download';

import DatePicker from './DatePicker';
import LoadingProgress from '@tafs/components/common/LoadingProgress';
import styles from './index.module.css';
import GQDataSource from './GQDataSource';
import CustomInstrumentsToolPanel from '../AgGridTools/CustomInstrumentsToolPanel';
import { constructColumnsDefs, getQueryFields } from './utils';
import { autoSizeColumnsUpToMaxWidth } from '@tafs/utils';
import NoData from '../Status/NoData';
import { NS } from '@tafs/i18n/i18nextConfig';
import CheckboxHeader from './CheckboxHeader';
import { downloadXls } from '@tafs/services/api';
import { notifyService } from '@tafs/services/notifications';

class DataTable extends Component {
  gridOptions = {
    localeTextFunc: (key, defaultValue) =>
      this.props.t(key, { ns: NS.AG_GRID }),
    overlayNoRowsTemplate: NoData.stringify(),
    frameworkComponents: {
      customLoadingOverlay: LoadingProgress,
      checkboxHeader: CheckboxHeader,
      datePicker: DatePicker,
      customInstrumentsToolPanel: (props) =>
        CustomInstrumentsToolPanel({
          ...props,
          stateKey: this.props.stateKey,
          filterStateKey:
            this.props.saveFiltersTools &&
            `${this.props.username}_${this.props.stateKey}_filterstate`,
        }),
      ...this.props.frameworkComponents,
    },
    loadingOverlayComponent: 'customLoadingOverlay',
    onFirstDataRendered(params) {
      autoSizeColumnsUpToMaxWidth(params.columnApi);
    },
    defaultColDef: {
      filter: true,
      sortable: true,
      resizable: true,
      useValueFormatterForExport: true,
      useValueParserForImport: true,
    },
    columnDefs: constructColumnsDefs(
      this.props.columnDefs,
      this.props.entityType,
      this.props.gqlTypes,
      !!this.props.detailCellRendererParams
    ),
    rowModelType: 'serverSide',
    animateRows: true,
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressSideButtons: true,
            suppressColumnFilter: true,
          },
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        ...(this.props.stateKey
          ? [
              {
                id: 'customInstruments',
                labelKey: 'customInstruments',
                iconKey: 'smallLeft',
                toolPanel: 'customInstrumentsToolPanel',
              },
            ]
          : []),
      ],
      defaultToolPanel: 'filters',
    },
    pagination: true,
    blockLoadDebounceMillis: 200,
    serverSideSortingAlwaysResets: true,
    valueCache: false,
    maxBlocksInCache: 0,
    cacheBlockSize: 0,
    reactNext: true,
    rowHeight: 24,
    suppressPropertyNamesCheck: true,
    ensureDomOrder: true,
    allowDragFromColumnsToolPanel: true,
    enableRangeSelection: true,
    suppressMultiRangeSelection: true,
    suppressRowClickSelection: true,
  };

  onGridReady = (grid) => {
    const { api, columnApi } = grid;
    const { entityType, columns, filters, onGridReady } = this.props;

    this.gridApi = api;
    this.gridColumnApi = columnApi;

    const queryFields = getQueryFields(
      this.props.columnDefs,
      this.props.entityType,
      this.props.gqlTypes
    );

    const dataSource = new GQDataSource({
      gridOptions: this.gridOptions,
      entityType,
      columns,
      queryFields,
      filters,
    });

    this.dataSource = dataSource;
    api.setServerSideDatasource(
      dataSource
    );

    this.restoreColState();

    if (onGridReady) {
      onGridReady({
        ...grid,
        reloadData: this.reloadData,
        getDisplayedRowCount: this.getDisplayedRowCount,
        getDisplayedRowAtIndex: this.getDisplayedRowAtIndex,
      });
    }
  };

  onColumnVisible = (grid) => {
    const { onColumnVisible } = this.props;

    if (onColumnVisible) onColumnVisible(grid);
  };

  getDisplayedRowCount = () => this.gridApi.getDisplayedRowCount();

  getDisplayedRowAtIndex = (index) =>
    this.gridApi.getDisplayedRowAtIndex(index);

  reloadData = () => {
    const { entityType, columns, filters } = this.props;

    const queryFields = getQueryFields(
      this.props.columnDefs,
      this.props.entityType,
      this.props.gqlTypes
    );

    if (this.gridApi) {
      this.gridApi.setServerSideDatasource(
        new GQDataSource({
          gridOptions: this.gridOptions,
          entityType,
          columns,
          queryFields,
          filters,
        })
      );
      this.gridApi.purgeServerSideCache();
    }
    const dataSource = new GQDataSource({
      gridOptions: this.gridOptions,
      entityType,
      columns,
      queryFields,
      filters,
    });

    this.dataSource = dataSource;
    this.gridApi.setServerSideDatasource(
      dataSource
    );
    this.gridApi.purgeServerSideCache();
  };
  restoreColState = () => {
    const { stateKey, username } = this.props;

    if (stateKey) {
      try {
        const gridStateString = localStorage.getItem(`${username}_${stateKey}`);
        if (gridStateString) {
          const { colState, sortModel, filterModel } =
            JSON.parse(gridStateString);
          colState && this.gridColumnApi.setColumnState(colState);
          sortModel && this.gridApi.setSortModel(sortModel);
          filterModel && this.gridApi.setFilterModel(filterModel);
        }
      } catch {}
    }
  };

  componentCleanUp = () => {
    const { stateKey, username } = this.props;

    if (stateKey && this.gridColumnApi && this.gridApi) {
      localStorage.setItem(
        `${username}_${stateKey}`,
        JSON.stringify({
          colState: this.gridColumnApi.getColumnState(),
          sortModel: this.gridApi.getSortModel(),
          filterModel: this.gridApi.getFilterModel(),
        })
      );
    }
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.componentCleanUp);
  }

  componentWillUnmount() {
    this.componentCleanUp();
    window.removeEventListener('beforeunload', this.componentCleanUp);
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps.filters, this.props.filters) ||
      !isEqual(prevProps.entityType, this.props.entityType)
    ) {
      this.reloadData();
    }
  }

  getContextMenu = (params) => {
    const { getContextMenuItems, disableXlsExport, t, i18n } = this.props;

    const copyCell = {
      icon: '<span class="ag-icon ag-icon-copy" unselectable="on"></span>',
      name: t('copyCell', { ns: NS.AG_GRID }),
      action: () => {
        try {
          const { gridApi } = this;
          const { rowIndex, column } = gridApi.getFocusedCell();
          const rowNode = gridApi.getDisplayedRowAtIndex(rowIndex);

          let value = gridApi.getValue(column.colId, rowNode);
          if (isNaN(value) || value === undefined) {
            value = rowIndex;
          } else if (column.colDef.valueFormatter) {
            value = column.colDef.valueFormatter({ value: value });
          } else if (column.colDef.cellRendererFramework) {
            const renderValue = column.colDef.cellRendererFramework({
              value: value,
              data: 'mock',
            });
            if (!isObject(renderValue) & renderValue) {
              value = renderValue;
            }
          }

          navigator.clipboard.writeText(value);
        } catch (error) {
          console.error(t('Cell content copy error'));
        }
      },
    };

    const exportXls = {
      icon: '<span class="ag-icon ag-icon-save" unselectable="on"></span>',
      disabled: !this.gridApi.serverSideRowModel.datasource?.totalElements,
      name: t('excelXlsExport', { ns: NS.AG_GRID }),
      action: () => {
        try {
          const query =
            this.gridApi.serverSideRowModel.datasource.getQueryForExport(
              this.props.columnDefs
            );

          notifyService.success(t('File download has been started'));
          downloadXls(query, i18n.language)
            .then((res) => {
              FileDownload(res, `export.xls`);
            })
            .catch(() => notifyService.error(t('Download error')));
        } catch (e) {
          const { message, count } = e;
          notifyService.warn(t(message, { count }));
        }
      },
    };

    let res = [
      ...(getContextMenuItems
        ? [...getContextMenuItems(params), 'separator']
        : []),
      'copy',
      'copyWithHeaders',
      ...(disableXlsExport ? [] : ['separator', exportXls]),
    ];

    let placeIndex = res.findIndex((item) => item === 'copy');
    if (placeIndex === -1) res.unshift(copyCell);
    else res.splice(placeIndex, 0, copyCell);

    return res;
  };

  getMainMenuItems = ({ api, column, defaultItems }) => {
    const { t } = this.props;

    const menuItem = {
      name: t('Copy column', { ns: NS.AG_GRID }),
      action: function () {
        api.clearRangeSelection();
        api.addCellRange({
          rowStartIndex: api.paginationProxy.topRowBounds.rowIndex,
          rowEndIndex:
            api.paginationProxy.topRowBounds.rowIndex +
            api.paginationProxy.pageSize -
            1,
          columnStart: column.colId,
          columnEnd: column.colId,
        });
        api.copySelectedRangeToClipboard();
        api.clearRangeSelection();
      },
    };
    defaultItems.splice(4, 0, menuItem);
    return defaultItems;
  };

  onFilterChanged = ({ api }) => {
    const { onFilterChanged: onFilterChangedProps, rowSelection } = this.props;
    if (onFilterChangedProps) onFilterChangedProps();
    if (rowSelection) api.deselectAll();
  };

  onModelUpdated = ({ api }) => {
    if (this.props?.onModelUpdated) {
      const filters = this.dataSource?.combinedFilters || {};
      this.props.onModelUpdated(filters);
    }
    if (this.props?.setAllRows) {
      const allRowData = [];
      api.forEachNode((node) => {
        allRowData.push(node.data);
      });
      this.props.setAllRows(allRowData);
    }
  };

  onSortChanged = ({ api }) => {
    const { onSortChanged: onSortChangedProps, rowSelection } = this.props;
    if (onSortChangedProps) onSortChangedProps();
    if (rowSelection) api.deselectAll();
  };

  render() {
    const {
      style,
      className,
      detailCellRendererParams,
      isRowMaster,
      getRowClass,
      onSelectionChanged,
      rowSelection,
      containerClassName,
      editType,
      onCellValueChanged,
      suppressClickEdit,
      rowClassRules,
      isRowSelectable,
      getRowStyle,
      pinnedTopRowData,
      onRowClicked,
    } = this.props;

    return (
      <div
        style={{ width: '100%', height: '100%' }}
        className={containerClassName}
      >
        <div
          className={classNames('ag-theme-balham', className, styles.root)}
          style={{
            ...style,
            height: '100%',
            paddingTop: '25px',
            boxSizing: 'border-box',
          }}
        >
          <AgGridReact
            onSortChanged={this.onSortChanged}
            onFilterChanged={this.onFilterChanged}
            suppressClickEdit={suppressClickEdit}
            rowModelType="serverSide"
            onGridReady={this.onGridReady}
            gridOptions={this.gridOptions}
            editType={editType}
            detailCellRendererParams={detailCellRendererParams}
            masterDetail={!!detailCellRendererParams}
            isRowMaster={isRowMaster}
            getContextMenuItems={this.getContextMenu}
            getRowClass={getRowClass}
            rowSelection={rowSelection || 'multiple'}
            onSelectionChanged={onSelectionChanged}
            onCellValueChanged={onCellValueChanged}
            rowClassRules={rowClassRules}
            isRowSelectable={isRowSelectable}
            getMainMenuItems={this.getMainMenuItems}
            onColumnVisible={this.onColumnVisible}
            getRowStyle={getRowStyle}
            pinnedTopRowData={pinnedTopRowData}
            onRowClicked={onRowClicked}
            onModelUpdated={this.onModelUpdated}
          />
        </div>
      </div>
    );
  }
}

DataTable.propTypes = {
  entityType: PropTypes.string,
  columns: PropTypes.array,
  className: PropTypes.string,
  detailCellRendererParams: PropTypes.shape({
    detailGridOptions: PropTypes.shape({
      columnDefs: PropTypes.array,
      onFirstDataRendered: PropTypes.func,
    }),
    getDetailRowData: PropTypes.func,
  }),
  isRowMaster: PropTypes.func,
};

const mapStateToProps = function (state) {
  return {
    gqlTypes: state.user.schema.types,
    username: state.user.username,
  };
};

export default connect(mapStateToProps)(
  withTranslation([NS.CONSTANTS, NS.AG_GRID])(DataTable)
);
