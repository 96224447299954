import { getIn } from 'formik';
import { format } from 'date-fns';
import { groupBy, isObject, isString } from 'lodash';

import { STATE_KEY } from '@tafs/constants/state';
import { VERSION_KEY, CURRENT_VERSION } from '@tafs/constants/version';

export const loadState = () => {
  try {
    if (CURRENT_VERSION !== localStorage.getItem(VERSION_KEY)) {
      localStorage.removeItem(STATE_KEY);
      localStorage.setItem(VERSION_KEY, CURRENT_VERSION);
      return undefined;
    }

    const serializedState = localStorage.getItem(STATE_KEY);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(STATE_KEY, serializedState);
  } catch (err) {
    console.error(err);
  }
};

export const getQueryObject = (locationSearchString) =>
  locationSearchString
    .substr(1)
    .split('&')
    .reduce((queryObject, keyValuePair) => {
      const [key, value] = keyValuePair.split('=');

      queryObject[key] = value;
      return queryObject;
    }, {});

export const getQueryArray = (locationSearchString) =>
  locationSearchString && locationSearchString.length > 0
    ? locationSearchString
        .substr(1)
        .split('&')
        .reduce((queryArray, keyValuePair) => {
          const [key, value] = keyValuePair.split('=');

          queryArray.push({ name: key, value });
          return queryArray;
        }, [])
    : [];

export const getMergedFiltersQuery = (configureFilters, slicedFilters = []) => {
  const configureFiltersObj = Object.values(
    groupBy(configureFilters, 'name')
  ).reduce((res, items) => {
    const name = items[0].name;

    res[name] = {
      name,
      value: [...new Set(items.map((i) => i.value))].join(','),
    };

    return res;
  }, {});

  slicedFilters.forEach(
    (filter) => (configureFiltersObj[filter.name] = filter)
  );

  return Object.values(configureFiltersObj)
    .map(({ name, value }) => `${name}=${value}`)
    .join('&');
};

export function hasError(fieldName, touched, errors) {
  return getIn(touched, fieldName) && !!getIn(errors, fieldName);
}

export function isValidDateTime(timestamp) {
  var d = new Date(timestamp);
  var dNum = d.getTime();
  return !!dNum;
}

export function isValidDateString(dateString) {
  var regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateString.match(regEx)) return false; // Invalid format
  var d = new Date(dateString);
  var dNum = d.getTime();
  if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
  return d.toISOString().slice(0, 10) === dateString;
}

export function formatDate(value, withoutTimePart) {
  if (!value) return '';

  const dateArray = value.split(/[- :]/g);
  dateArray[1] = Number(dateArray[1] - 1); // modify month so it starts from 0
  const date = new Date(...dateArray);

  return withoutTimePart
    ? format(date, 'yyyy-MM-dd')
    : format(date, 'yyyy-MM-dd HH:mm:ss');
}

export function formatTime(value) {
  if (!value) return '';
  if (value.indexOf(':') > 0 || value.indexOf('-') > 0) return value;

  const date = new Date(value);

  return format(date, 'HH:mm:ss');
}

export function getEpoch(value) {
  if (!value) return '';

  const dateArray = value.split(/[- :]/g);
  dateArray[1] = Number(dateArray[1] - 1); // modify month so it starts from 0
  const date = new Date(...dateArray);

  return date.getTime();
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const formatNumber = (value, maxFractionDig = 0) =>
  value != null &&
  (Number(value) || 0).toLocaleString(undefined, {
    maximumFractionDigits: maxFractionDig,
  });

export const partial =
  (fn, x) =>
  (...args) =>
    fn(x, ...args);

export const isUUID = (str) =>
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi.test(
    str
  );

export const translateObj = (obj, translateFunc) =>
  Object.keys(obj).reduce((acc, key) => {
    if (Array.isArray(obj[key]))
      acc[key] = obj[key].map((item) => translateFunc(item));
    else if (isObject(obj[key]))
      acc[key] = translateObj(obj[key], translateFunc);
    else if (isString(obj[key]))
      // ternary because empty strings break translation
      acc[key] = obj[key] ? translateFunc(key) : obj[key];
    return acc;
  }, {});

export const capitalize = (word) =>
  `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`;

export const autoSizeColumnsUpToMaxWidth = (columnApi) => {
  const MAX_COLUMN_AUTO_WIDTH = 300;

  columnApi.getAllColumns().forEach((column) => {
    columnApi.autoSizeColumn(column.getId());
    if (column.getActualWidth() > MAX_COLUMN_AUTO_WIDTH) {
      column.setActualWidth(MAX_COLUMN_AUTO_WIDTH);
    }
  });
};

export const addPatternEscapeChars = (str) => str.replaceAll(/[()%_]/g, '\\$&');

export const secondsToTime = (seconds) => {
  if (!seconds) return '';

  const h = seconds / 3600;
  const m = (seconds % 3600) / 60;
  const s = (seconds % 3600) % 60;

  const twoDigits = (num) =>
    Math.floor(num).toLocaleString(undefined, {
      minimumIntegerDigits: 2,
    });

  return `${twoDigits(h)}:${twoDigits(m)}:${twoDigits(s)}`;
};
